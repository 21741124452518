

























import {
	Component,
	Vue
} from "vue-property-decorator";

import vFreeApply from '@/components/v-free-apply.vue';
import defaultItem from "@/utils/defaultItem";
@Component({
	components: {
		vFreeApply
	},
})
export default class About extends Vue {
	private email = 'heigukeji.163.com';
}
